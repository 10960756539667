@media (max-width: $screen-sm) {
    // sm-view
    .company-points { 
        .company-points-holder { 
            .points {
                width: 100%;
                margin: 0;
                .point {
                    width: 100%;
                    height: 85px;
                    padding: 0;
                    .point-holder { 
                        padding: 15px 15px;
                        .icon {
                            width: 30px;
                            height: 30px;
                        }

                        .text {
                            width: calc(100% - 30px);
                            padding-left: 15px;
                            .text-holder {
                                font-size: 12px;
                            }
                        }
                    }
                } 
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
