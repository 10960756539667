.main-slider { 
    display: inline-block;
    width: 100%;
    height: 540px;
	.main-slider-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
		.main-image-slider { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
			.main-image-slider-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				.image { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
					.image-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
						a { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
							img { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
							}
						}
					}
				}
			}
		}

		.text-slider-container { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
            pointer-events: none;
            touch-action: none;
			.text-slider-container-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				.wrapper-inside { 
                    height: 100%;
                    .text-container {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        .main-text-sldier { 
                            width: 100%;
                            pointer-events: all;
                            touch-action: auto;
                            .main-text-slider-holder { 
    
                                .text { 
                                    
                                    .text-holder { 
                                        width: 45%;
                                    }
                                }
                            }
    
                            .controlls { 
                                width: 100%;
                                margin: 30px 0 0;
                                .controlls-holder { 
    
                                    .prev, .next { 
                                        position: relative;
                                        float: left;
                                        top: auto;
                                        left: auto;
                                        transform: none;
                                        left: auto;
                                        right: auto;
                                        svg { 
    
                                        }
                                    }
    
                                    .next { 
                                        margin-left: 16px;
                                        svg { 
    
                                        }
                                    }
                                }
                            }
                        }
                    }
					
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/main_slider";
@import "../../media/mobile/includes/index/main_slider";
