.main-slider {
  display: inline-block;
  width: 100%;
  height: 540px;
}
.main-slider .main-slider-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-slider .main-slider-holder .main-image-slider {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.main-slider .main-slider-holder .main-image-slider .main-image-slider-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .main-image-slider .main-image-slider-holder .image {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .main-image-slider .main-image-slider-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .main-image-slider .main-image-slider-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .main-image-slider .main-image-slider-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-slider .main-slider-holder .text-slider-container {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside {
  height: 100%;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier {
  width: 100%;
  pointer-events: all;
  touch-action: auto;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .main-text-slider-holder .text .text-holder {
  width: 45%;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .controlls {
  width: 100%;
  margin: 30px 0 0;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .controlls .controlls-holder .prev, .main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .controlls .controlls-holder .next {
  position: relative;
  float: left;
  top: auto;
  left: auto;
  transform: none;
  left: auto;
  right: auto;
}
.main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .controlls .controlls-holder .next {
  margin-left: 16px;
}
@media (max-width: 1469px) {
  .main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .main-text-slider-holder .text .text-holder {
    width: 60%;
  }
}
@media (max-width: 1199px) {
  .main-slider {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container {
    align-items: flex-start;
    box-sizing: border-box;
    padding: 40px 0 0;
  }
  .main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .main-text-slider-holder .text .text-holder {
    width: 100%;
  }
  .main-slider .main-slider-holder .text-slider-container .text-slider-container-holder .wrapper-inside .text-container .main-text-sldier .controlls {
    margin: 20px 0 0;
  }
}
.company-points {
  display: inline-block;
  width: 100%;
  margin: 0 0 -30px;
}
.company-points .company-points-holder {
  display: inline-block;
  width: 100%;
  padding: 30px 0;
}
.company-points .company-points-holder .points {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.company-points .company-points-holder .points .point {
  width: 33.333%;
  height: auto;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 0 30px;
}
.company-points .company-points-holder .points .point .point-holder {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #F2F5F7;
  box-sizing: border-box;
  padding: 25px 25px;
}
.company-points .company-points-holder .points .point .point-holder .icon {
  width: 60px;
  height: 60px;
}
.company-points .company-points-holder .points .point .point-holder .icon .icon-holder {
  width: 100%;
  height: 100%;
}
.company-points .company-points-holder .points .point .point-holder .icon .icon-holder svg {
  width: 100%;
  height: auto;
}
.company-points .company-points-holder .points .point .point-holder .text {
  width: calc(100% - 60px);
  box-sizing: border-box;
  padding-left: 20px;
}
.company-points .company-points-holder .points .point .point-holder .text .text-holder {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

@media (max-width: 1469px) {
  .company-points {
    margin: 0 0 -20px;
  }
  .company-points .company-points-holder .points {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .company-points .company-points-holder .points .point {
    width: 50%;
    padding: 0 10px;
    margin: 0 0 20px;
  }
}
@media (max-width: 1199px) {
  .company-points {
    margin: 0 0 -10px;
  }
  .company-points .company-points-holder .points {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .company-points .company-points-holder .points .point {
    width: 50%;
    padding: 0 5px;
    margin: 0 0 10px;
  }
  .company-points .company-points-holder .points .point .point-holder .icon {
    width: 45px;
    height: 45px;
  }
  .company-points .company-points-holder .points .point .point-holder .text {
    width: calc(100% - 45px);
  }
  .company-points .company-points-holder .points .point .point-holder .text .text-holder {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .company-points .company-points-holder .points {
    width: 100%;
    margin: 0;
  }
  .company-points .company-points-holder .points .point {
    width: 100%;
    height: 85px;
    padding: 0;
  }
  .company-points .company-points-holder .points .point .point-holder {
    padding: 15px 15px;
  }
  .company-points .company-points-holder .points .point .point-holder .icon {
    width: 30px;
    height: 30px;
  }
  .company-points .company-points-holder .points .point .point-holder .text {
    width: calc(100% - 30px);
    padding-left: 15px;
  }
  .company-points .company-points-holder .points .point .point-holder .text .text-holder {
    font-size: 12px;
  }
}
.glass-form {
  display: inline-block;
  width: 100%;
  padding: 50px 0 50px;
}
.glass-form.padding-to-margin {
  padding: 0;
  margin: 0 0 34px;
}
.glass-form .glass-form-holder {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 41px;
}
.glass-form .glass-form-holder .selection-form {
  display: inline-block;
  width: 100%;
  background-color: #F2F5F7;
  box-sizing: border-box;
  padding: 40px;
}
.glass-form .glass-form-holder .selection-form form {
  display: inline-block;
  width: calc(100% + 60px);
  margin-left: -30px;
}
.glass-form .glass-form-holder .selection-form form .fields {
  width: calc(100% - 395px);
  float: left;
  box-sizing: border-box;
  padding: 0 30px;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
  float: left;
  box-sizing: border-box;
  padding: 0 5px;
}
.glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col .form-field {
  display: inline-block;
  width: 100%;
  margin: 0;
}
.glass-form .glass-form-holder .selection-form form .action-col {
  width: 395px;
  float: left;
  box-sizing: border-box;
  padding: 0 30px;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit {
  display: inline-block;
  width: 100%;
}
.glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit .btn {
  display: inline-block;
  width: 100%;
}

@media (max-width: 1469px) {
  .glass-form {
    padding: 30px 0 30px;
  }
  .glass-form.padding-to-margin {
    margin: 0 0 41px;
  }
  .glass-form .glass-form-holder .selection-form {
    padding: 29px 30px 24px;
  }
  .glass-form .glass-form-holder .selection-form form {
    width: calc(100% + 15px);
    margin-left: -7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields {
    width: calc(100% - 300px);
    padding: 0 7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
    width: calc(100% + 15px);
    margin-left: -7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
    padding: 0 7.5px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col {
    width: 300px;
    padding: 0 7.5px;
  }
}
@media (max-width: 1199px) {
  .glass-form.padding-to-margin {
    margin: 0 0 24px;
  }
  .glass-form .glass-form-holder .heading {
    margin: 0 0 29px;
  }
  .glass-form .glass-form-holder .selection-form {
    background-color: transparent;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form {
    width: calc(100% + 22px);
    margin-left: -11px;
  }
  .glass-form .glass-form-holder .selection-form form .fields {
    width: calc(100% - 67px);
    padding: 0 11px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
    padding: 0 5px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col {
    width: 67px;
    padding: 0 11px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit .btn {
    float: right;
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 767px) {
  .glass-form {
    padding: 20px 0 20px;
  }
  .glass-form .glass-form-holder .selection-form form {
    width: 100%;
    margin: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields {
    width: 100%;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group {
    width: 100%;
    margin: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col {
    width: 100%;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form .fields .fields-holder .form-group .form-col .form-field {
    margin: 0 0 8px;
  }
  .glass-form .glass-form-holder .selection-form form .action-col {
    width: 100%;
    padding: 0;
  }
  .glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action {
    margin: 21px 0 0;
  }
  .glass-form .glass-form-holder .selection-form form .action-col .action-col-holder .action .submit .btn {
    width: 100%;
  }
}
.models-list-block {
  display: inline-block;
  width: 100%;
  padding: 23px 0 80px;
}
.models-list-block.padding-to-margin {
  padding: 0;
  margin: 0 0 45px;
}
.models-list-block .models-list-block-holder {
  display: inline-block;
  width: 100%;
}
.models-list-block .models-list-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.models-list-block .models-list-block-holder .models-list {
  display: inline-block;
  width: 100%;
}
.models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item {
  margin: 0 0 14.5px;
}
.models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item .model-item-holder .name a .name {
  width: 100%;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder {
  display: inline-block;
  width: 100%;
  column-count: 6;
  column-gap: 31px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder {
  display: inline-block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-left: 35px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .letter {
  position: absolute;
  left: 0;
  top: -2px;
  font-size: 28px;
  font-weight: 500;
  line-height: 140%;
  color: #CF2127;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name {
  display: inline-block;
  width: 100%;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #000;
  display: flex;
  align-items: center;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a:hover {
  color: #CF2127;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon {
  float: left;
  margin-right: 15px;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .name {
  width: calc(100% - 40px - 15px);
  float: left;
}
.models-list-block .models-list-block-holder .action {
  margin: 20px 0 0;
}
.models-list-block .models-list-block-holder .action .btn {
  padding: 14px 31px;
}

@media (max-width: 1469px) {
  .models-list-block {
    padding: 30px 0 58px;
  }
  .models-list-block .models-list-block-holder .models-list.lg-4-col .models-list-holder {
    column-count: 4;
  }
  .models-list-block .models-list-block-holder .models-list.lg-6-col .models-list-holder {
    column-count: 6;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder {
    column-count: 3;
    column-gap: 21px;
  }
}
@media (max-width: 1199px) {
  .models-list-block {
    padding: 25px 0 50px;
  }
  .models-list-block .models-list-block-holder .heading {
    margin: 0 0 31px;
  }
  .models-list-block .models-list-block-holder .models-list.md-3-col .models-list-holder {
    column-count: 3;
  }
  .models-list-block .models-list-block-holder .models-list.md-6-col .models-list-holder {
    column-count: 6;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a {
    font-size: 14px;
  }
  .models-list-block .models-list-block-holder .action {
    margin: 10px 0 0;
  }
  .models-list-block .models-list-block-holder .action .btn {
    padding: 14px 42px;
  }
}
@media (max-width: 767px) {
  .models-list-block {
    padding: 25px 0 40px;
  }
  .models-list-block .models-list-block-holder .heading {
    margin: 0 0 29px;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs {
    overflow-y: scroll;
    width: 110.35%;
    margin-left: -5.175%;
    box-sizing: border-box;
    padding: 0 5.175%;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs::-webkit-scrollbar {
    display: none !important;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs.tighter .models-list-holder {
    width: 894px;
  }
  .models-list-block .models-list-block-holder .models-list.scrollabale-xs .models-list-holder {
    column-count: 6;
    width: 1236px;
  }
  .models-list-block .models-list-block-holder .models-list.xs-6-col .models-list-holder {
    column-count: 6;
  }
  .models-list-block .models-list-block-holder .models-list.xs-2-col .models-list-holder {
    column-count: 2;
    column-gap: 14px;
  }
  .models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item {
    margin: 0 0 10px;
  }
  .models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item .model-item-holder {
    padding-left: 28px;
  }
  .models-list-block .models-list-block-holder .models-list.tighter .models-list-holder .model-item .model-item-holder .name a {
    font-size: 10px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item {
    margin: 0 0 15px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder {
    padding-left: 23px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .letter {
    font-size: 18px;
    line-height: 120%;
    top: 4px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a {
    font-size: 14px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon {
    margin-right: 10px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .icon img {
    width: 30px;
    height: 30px;
  }
  .models-list-block .models-list-block-holder .models-list .models-list-holder .model-item .model-item-holder .name a .name {
    width: calc(100% - 30px - 10px);
  }
  .models-list-block .models-list-block-holder .action {
    width: 100%;
    margin: 12px 0 0;
  }
  .models-list-block .models-list-block-holder .action .btn {
    width: 100%;
    text-align: center;
  }
}
.services-block {
  display: inline-block;
  width: 100%;
  background-color: #F2F5F7;
  padding: 79px 0 78px;
}
.services-block .services-block-holder {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder > .action {
  display: inline-block;
  width: 100%;
  margin: 28px 0 0;
}
.services-block .services-block-holder > .action .btn {
  text-align: center;
  width: 100%;
  padding: 13px 14px;
}
.services-block .services-block-holder .services-grid {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder .services-grid .services-grid-holder {
  width: 100%;
  display: grid;
  grid-auto-rows: 412px;
  grid-template-columns: 49% 1fr 1fr;
  grid-template-rows: 412px 412px;
  gap: 30px 30px;
  grid-template-areas: "big . ." "big . .";
}
.services-block .services-block-holder .services-grid .services-grid-holder .big {
  grid-area: big;
}
.services-block .services-block-holder .services-grid .services-grid-holder .service-item {
  width: 100%;
  height: 100%;
}

.service-item.big .service-item-holder .image {
  height: 630px;
}
.service-item.big .service-item-holder .desc .desc-holder .name {
  font-size: 28px;
  line-height: 120%;
}

.service-item {
  display: inline-block;
  width: 100%;
  background-color: white;
}
.service-item .service-item-holder {
  display: inline-block;
  width: 100%;
}
.service-item .service-item-holder .image {
  float: left;
  width: 100%;
  height: 200px;
}
.service-item .service-item-holder .image a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.service-item .service-item-holder .image a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.service-item .service-item-holder .image a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.service-item .service-item-holder .desc {
  float: left;
  width: 100%;
}
.service-item .service-item-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 30px 25px;
}
.service-item .service-item-holder .desc .desc-holder .name {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  margin: 0 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
}
.service-item .service-item-holder .desc .desc-holder .name a {
  color: #000;
}
.service-item .service-item-holder .desc .desc-holder .name a:hover {
  color: #CF2127;
}
.service-item .service-item-holder .desc .desc-holder .price {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 140%;
  color: #979797;
  margin: 0 0 21px;
}
.service-item .service-item-holder .desc .desc-holder > .text {
  display: inline-block;
  width: 100%;
  height: 44px;
  font-size: 16px;
  line-height: 140%;
  color: #979797;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  margin: 0 0 17px;
}
.service-item .service-item-holder .desc .desc-holder .action {
  display: inline-block;
  width: 100%;
}
.service-item .service-item-holder .desc .desc-holder .action a {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}
.service-item .service-item-holder .desc .desc-holder .action a .text {
  float: left;
}
.service-item .service-item-holder .desc .desc-holder .action a .icon {
  float: left;
  width: 17px;
  height: 11px;
  margin: 0 0 0 11px;
  padding: 6px 0 0;
}
@media (max-width: 1469px) {
  .services-block {
    padding: 61px 0 61px;
  }
  .services-block .services-block-holder .services-grid .services-grid-holder {
    gap: 20px 20px;
    grid-template-columns: 49.1% 1fr 1fr;
    grid-template-rows: 400px 400px;
    grid-auto-rows: 400px;
  }
  .service-item.big .service-item-holder .image {
    height: 609px;
  }
  .service-item .service-item-holder .desc .desc-holder {
    padding: 25px 25px 0;
  }
  .service-item .service-item-holder .desc .desc-holder .name {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
@media (max-width: 1199px) {
  .services-block {
    padding: 49px 0 59px;
    overflow: hidden;
    margin: 0 0 -3px;
  }
  .services-block .services-block-holder .services-grid .slick-slider {
    width: calc(100% + 10px);
    margin-left: -5px;
    padding: 0 30.8% 0 0;
    box-sizing: border-box;
  }
  .services-block .services-block-holder .services-grid .slick-slider .slick-list {
    overflow: visible;
  }
  .services-block .services-block-holder .services-grid .slick-slider .slick-list .slick-track .slick-slide {
    padding: 0 5px;
  }
  .services-block .services-block-holder .services-grid .services-grid-holder {
    display: inline-block;
  }
  .service-item.big .service-item-holder .image {
    height: 200px;
  }
  .service-item.big .service-item-holder .desc .desc-holder .name {
    font-size: 16px;
    line-height: 120%;
  }
  .service-item .service-item-holder .desc .desc-holder {
    padding: 25px 25px 20px;
  }
  .service-item .service-item-holder .desc .desc-holder .name {
    font-size: 16px;
    line-height: 120%;
    margin: 0 0 5px;
  }
  .service-item .service-item-holder .desc .desc-holder .price {
    font-size: 14px;
    margin: 0 0 15px;
  }
  .service-item .service-item-holder .desc .desc-holder > .text {
    font-size: 14px;
    margin: 0 0 10px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a {
    font-size: 14px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a .icon {
    padding: 3px 0 0;
  }
}
@media (max-width: 767px) {
  .services-block {
    padding: 39px 0 49px;
  }
  .services-block .services-block-holder .services-grid .slick-slider {
    width: calc(100% + 15px);
    margin-left: -7.5px;
    padding: 0 7.5% 0 0;
  }
  .services-block .services-block-holder .services-grid .slick-slider .slick-list .slick-track .slick-slide {
    padding: 0 7.5px;
  }
  .service-item.big .service-item-holder .desc .desc-holder .name {
    font-size: 14px;
  }
  .service-item .service-item-holder .desc .desc-holder {
    padding: 20px 20px 15px;
  }
  .service-item .service-item-holder .desc .desc-holder .name {
    font-size: 14px;
    margin: 0 0 5px;
  }
  .service-item .service-item-holder .desc .desc-holder .price {
    font-size: 12px;
    margin: 0 0 15px;
  }
  .service-item .service-item-holder .desc .desc-holder > .text {
    font-size: 12px;
    margin: 0 0 10px;
    height: 33px;
    margin: 0 0 16px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a {
    font-size: 12px;
  }
  .service-item .service-item-holder .desc .desc-holder .action a .icon {
    padding: 3px 0 0;
  }
  .service-item .service-item-holder .desc .desc-holder .action a .icon svg {
    width: 12px;
    height: auto;
  }
}
.news-block {
  display: inline-block;
  width: 100%;
  background-color: #F2F5F7;
  padding: 0 0 38px;
}
.news-block .news-block-holder {
  display: inline-block;
  width: 100%;
}
.news-block .news-block-holder .news-list-block {
  padding: 38px 40px 33px;
}

.news-list-block.standalone {
  margin: 0 0 65px;
}
.news-list-block.standalone .news-list-block-holder .news-headings-list {
  padding-left: 71px;
}

.news-list-block {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
}
.news-list-block .news-list-block-holder {
  display: inline-block;
  width: 100%;
}
.news-list-block .news-list-block-holder .spotlight {
  width: 34%;
  float: left;
}
.news-list-block .news-list-block-holder .news-headings-list {
  width: 66%;
  float: left;
  box-sizing: border-box;
  padding-left: 98px;
  padding-top: 33px;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder {
  display: inline-block;
  width: 100%;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 33px;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item:last-of-type {
  margin: 0;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder {
  display: inline-block;
  width: 100%;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name {
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  margin: 0 0 9px;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name a {
  color: #000;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name a:hover {
  color: #CF2127;
}
.news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .date {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #979797;
}

.news-item {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.news-item .news-item-holder .news-info {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  min-height: 294px;
}
.news-item .news-item-holder .news-info .date {
  text-align: left;
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 10px;
}
.news-item .news-item-holder .news-info .name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  margin: 0 0 19px;
}
.news-item .news-item-holder .news-info .name a {
  color: #000;
}
.news-item .news-item-holder .news-info .name a:hover {
  color: #CF2127;
}
.news-item .news-item-holder .news-info .text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-clamp: 5;
  box-orient: vertical;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  color: #979797;
}
.news-item .news-item-holder .action {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder .action .btn {
  width: 280px;
  text-align: center;
}

@media (max-width: 1469px) {
  .news-block {
    padding: 0 0 16px;
  }
  .news-block .news-block-holder .news-list-block {
    padding: 38px 40px 39px;
  }
  .news-list-block.standalone {
    margin: 0 0 65px;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list {
    padding-left: 31px;
    padding-top: 33px;
  }
  .news-list-block .news-list-block-holder .spotlight {
    width: 45%;
  }
  .news-list-block .news-list-block-holder .news-headings-list {
    width: 55%;
    padding-left: 107px;
    padding-top: 35px;
  }
}
@media (max-width: 1199px) {
  .news-block {
    padding: 0 0 32px;
  }
  .news-block .news-block-holder .news-list-block {
    padding: 30px 30px 30px;
  }
  .news-list-block.standalone {
    margin: 0 0 50px;
  }
  .news-list-block.standalone .news-list-block-holder .spotlight {
    width: 41%;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list {
    width: 59%;
    padding-left: 59px;
    padding-top: 29px;
  }
  .news-list-block .news-list-block-holder .spotlight {
    width: 38%;
  }
  .news-list-block .news-list-block-holder .news-headings-list {
    width: 62%;
    padding-left: 61px;
    padding-top: 30px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
    margin: 0 0 31px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name {
    font-size: 16px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .date {
    font-size: 14px;
  }
  .news-item .news-item-holder .news-info {
    min-height: 297px;
  }
  .news-item .news-item-holder .news-info .date {
    font-size: 14px;
  }
  .news-item .news-item-holder .news-info .name {
    -webkit-line-clamp: 4;
    line-clamp: 4;
    margin: 0 0 21px;
  }
  .news-item .news-item-holder .news-info .text {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .news-block {
    padding: 0 0 9px;
  }
  .news-block .news-block-holder .news-list-block {
    padding: 20px 20px 20px;
  }
  .news-list-block.standalone {
    margin: 0 0 15px;
  }
  .news-list-block.standalone .news-list-block-holder .spotlight {
    width: 100%;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list {
    width: 100%;
    padding-left: 0;
    padding-top: 0;
  }
  .news-list-block.standalone .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
    margin: 0 0 32px;
  }
  .news-list-block .news-list-block-holder .spotlight {
    width: 100%;
    margin: 0 0 42px;
  }
  .news-list-block .news-list-block-holder .news-headings-list {
    width: 100%;
    padding: 0;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item {
    margin: 0 0 22px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .name {
    font-size: 14px;
  }
  .news-list-block .news-list-block-holder .news-headings-list .news-headings-list-holder .news-heading-item .news-heading-item-holder .date {
    font-size: 12px;
  }
  .news-item .news-item-holder .news-info {
    min-height: auto;
  }
  .news-item .news-item-holder .news-info .date {
    font-size: 12px;
  }
  .news-item .news-item-holder .news-info .name {
    font-size: 18px;
  }
  .news-item .news-item-holder .news-info .text {
    font-size: 12px;
  }
  .news-item .news-item-holder .action {
    width: 100%;
    margin: 21px 0 0;
  }
  .news-item .news-item-holder .action .btn {
    width: 100%;
    padding: 13px 14px;
  }
}
.text-block {
  display: inline-block;
  width: 100%;
}
.text-block .text-block-holder {
  display: inline-block;
  width: 66%;
  color: #979797;
  padding: 46px 0 46px;
}
.text-block .text-block-holder.full {
  width: 100%;
}
@media (max-width: 1469px) {
  .text-block .text-block-holder {
    padding: 44px 0 44px;
    width: 88%;
  }
}
@media (max-width: 1199px) {
  .text-block .text-block-holder {
    padding: 28px 0 28px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .text-block .text-block-holder {
    padding: 40px 0 40px;
  }
}
.common-heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.common-heading h2, .common-heading .h2 {
  float: left;
  max-width: 80%;
}
.common-heading .action {
  float: right;
  max-width: 20%;
  text-align: right;
  padding: 11px 0 0;
}
.common-heading .action a {
  float: right;
}
.common-heading .action a .text {
  float: left;
}
.common-heading .action a .icon {
  float: left;
  width: 17px;
  height: 12px;
  margin-left: 9px;
  padding: 3px 0 0;
}

.slider-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 40px;
}
.slider-heading .controlls {
  margin-right: -7.5px;
}
.slider-heading .controlls .prev, .slider-heading .controlls .next {
  float: left;
  margin: 0 7.5px;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  transform: none;
}
.slider-heading .controlls .next {
  float: left;
}
@media (max-width: 1199px) {
  .common-heading {
    margin: 0 0 28px;
  }
  .common-heading .action {
    padding: 7px 0 0;
  }
  .common-heading .action a .icon {
    padding: 2px 0 0;
  }
  .slider-heading {
    margin: 0 0 30px;
  }
}
@media (max-width: 767px) {
  .common-heading h2, .common-heading .h2 {
    max-width: 65%;
  }
  .common-heading .action {
    max-width: 35%;
    font-size: 12px;
    line-height: 120%;
    padding: 5px 0 0;
  }
  .common-heading .action a .icon {
    margin-left: 6px;
  }
  .common-heading .action a .icon svg {
    width: 12px;
    height: auto;
  }
}