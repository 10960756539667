@media (max-width: $screen-lg) {
    // lg-md view
    .main-slider {
        .main-slider-holder { 
            .main-image-slider { 

            }

            .text-slider-container { 
                .text-slider-container-holder { 
                    .wrapper-inside { 
                        .text-container {
                            .main-text-sldier { 
                                .main-text-slider-holder { 
    
                                    .text { 
                                        
                                        .text-holder { 
                                            width: 60%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .main-slider {
        height: 400px;
    }
}
