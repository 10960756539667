@media (max-width: $screen-sm) {
    // sm-view
    .main-slider {
        .main-slider-holder { 
            .main-image-slider { 

            }

            .text-slider-container { 
                .text-slider-container-holder { 
                    .wrapper-inside { 
                        .text-container {
                            align-items: flex-start;
                            box-sizing: border-box;
                            padding: 40px 0 0;
                            .main-text-sldier { 
                                .main-text-slider-holder { 
    
                                    .text { 
                                        
                                        .text-holder { 
                                            width: 100%;
                                        }
                                    }
                                }

                                .controlls { 
                                    margin: 20px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
