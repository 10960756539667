.company-points { 
    display: inline-block;
    width: 100%;
    margin: 0 0 -30px;
    .company-points-holder { 
        display: inline-block;
        width: 100%;
        padding: 30px 0;
        .points { 
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
            .point { 
                width: 33.333%;
                height: auto;
                box-sizing: border-box;
                padding: 0 15px;
                margin: 0 0 30px;
                .point-holder { 
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background: $bg-secondary;
                    box-sizing: border-box;
                    padding: 25px 25px;
                    .icon { 
                        width: 60px;
                        height: 60px;
                        .icon-holder { 
                            width: 100%;
                            height: 100%;
                            svg { 
                                width: 100%;
                                height: auto;
                            }
                        }
                    }

                    .text { 
                        width: calc(100% - 60px);
                        box-sizing: border-box;
                        padding-left: 20px;
                        .text-holder { 
                            font-size: 16px;
                            font-weight: 500;
                            line-height: normal;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/company_points";
@import "../../media/mobile/includes/index/company_points";
