@media (max-width: $screen-lg) {
    // lg-md view
    .company-points { 
        margin: 0 0 -20px;
        .company-points-holder { 
            .points { 
                width: calc(100% + 20px);
                margin-left: -10px;
                .point {
                    width: 50%;
                    padding: 0 10px;
                    margin: 0 0 20px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .company-points { 
        margin: 0 0 -10px;
        .company-points-holder { 
            .points { 
                width: calc(100% + 10px);
                margin-left: -5px;
                .point {
                    width: 50%;
                    padding: 0 5px;
                    margin: 0 0 10px;
                    .point-holder { 
                        .icon { 
                            width: 45px;
                            height: 45px;
                            .icon-holder {

                            }
                        }

                        .text {
                            width: calc(100% - 45px);
                            .text-holder {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
